/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

function SEO({ description, lang, meta, title, image }) {
  const { wordpressSiteMetadata } = useStaticQuery(
    graphql`
      query {
        
          wordpressSiteMetadata {
		     	name      
				description
		    }
       
      }
    `
  )

  const metaDescription = description || wordpressSiteMetadata.description
  const metaTitle = title
	const titleTag = wordpressSiteMetadata.name + (title ? ` | ${title}` : '')

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={titleTag}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: metaTitle,
        },
        {
          property: `og:image`,
          content: image,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: wordpressSiteMetadata.name,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    >
      <link type="text/css" rel="stylesheet" href="https://hst2889.host04.loswebos.de/wp-includes/css/dist/block-library/style.min.css"></link>
    </Helmet>
  )
}

SEO.defaultProps = {
  lang: `de`,
  meta: [],
  description: ``,
}

SEO.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string,
}

export default SEO
